// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MultilingualMetaTags from './components/MultilingualMetaTags';
import MainContent from './MainContent';
import { LANGUAGES } from './i18n/resources';

const App = () => {
  const { i18n } = useTranslation();

  const detectUserLanguage = () => {
    const browserLang = navigator.language.split('-')[0];
    return LANGUAGES.find(lang => lang.code === browserLang)?.code || 'en';
  };

  return (
    <Router>
        <MultilingualMetaTags />
        <Routes>
          <Route path="/:lang" element={<MainContent />} />
          <Route 
            path="/" 
            element={<Navigate to={`/${detectUserLanguage()}`} replace />} 
          />
        </Routes>
    </Router>
  );
};

export default App;