import React from 'react';
import { useTranslation } from 'react-i18next';

const WeatherIcon = ({ weatherCode }) => {
  const { t } = useTranslation();
  let iconClass = 'wi wi-day-sunny';
  let description = 'weatherClear';

  switch (true) {
    case weatherCode === 0:
      iconClass = 'wi wi-day-sunny';
      description = 'weatherClear';
      break;
    case weatherCode >= 1 && weatherCode <= 3:
      iconClass = 'wi wi-day-cloudy';
      description = 'weatherPartlyCloudy';
      break;
    case weatherCode === 45 || weatherCode === 48:
      iconClass = 'wi wi-fog';
      description = 'weatherFog';
      break;
    case weatherCode >= 51 && weatherCode <= 55:
      iconClass = 'wi wi-sprinkle';
      description = 'weatherDrizzle';
      break;
    case weatherCode >= 56 && weatherCode <= 57:
      iconClass = 'wi wi-rain-mix';
      description = 'weatherFreezingDrizzle';
      break;
    case weatherCode >= 61 && weatherCode <= 65:
      iconClass = 'wi wi-rain';
      description = 'weatherRain';
      break;
    case weatherCode >= 66 && weatherCode <= 67:
      iconClass = 'wi wi-rain-mix';
      description = 'weatherFreezingRain';
      break;
    case weatherCode >= 71 && weatherCode <= 75:
      iconClass = 'wi wi-snow';
      description = 'weatherSnow';
      break;
    case weatherCode === 77:
      iconClass = 'wi wi-snow-wind';
      description = 'weatherSnowGrains';
      break;
    case weatherCode >= 80 && weatherCode <= 82:
      iconClass = 'wi wi-showers';
      description = 'weatherRainShowers';
      break;
    case weatherCode >= 85 && weatherCode <= 86:
      iconClass = 'wi wi-snow';
      description = 'weatherSnowShowers';
      break;
    case weatherCode === 95:
      iconClass = 'wi wi-thunderstorm';
      description = 'weatherThunderstorm';
      break;
    case weatherCode >= 96 && weatherCode <= 99:
      iconClass = 'wi wi-storm-showers';
      description = 'weatherThunderstormWithHail';
      break;
    default:
      iconClass = 'wi wi-day-sunny';
      description = 'weatherClear';
  }

  return (
    <div className="flex flex-col items-center">
      <i className={`${iconClass} text-4xl mb-2`}></i>
      <span className="font-semibold text-sm">{t(description)}</span>
    </div>
  );
};

export default WeatherIcon;