import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Users } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

const RecentLookups = () => {
  const { t } = useTranslation();
  const [lookups, setLookups] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    let ws;
    let reconnectAttempts = 0;
    const maxReconnectAttempts = 5;

    const connect = () => {
      setIsLoading(true);
      setError(null);
      console.log('Attempting to connect to WebSocket...');
      ws = new WebSocket('wss://api.ip2lo.com/ws');

      ws.onopen = () => {
        console.log('WebSocket Connected Successfully');
        setIsLoading(false);
        setError(null);
        reconnectAttempts = 0;
      };

      ws.onmessage = (event) => {
        console.log('Received message:', event.data);
        try {
          const data = JSON.parse(event.data);
          setLookups(data);
        } catch (error) {
          console.error('Error parsing message:', error);
          setError('Received invalid data from server');
        }
      };

      ws.onerror = (error) => {
        console.error('WebSocket Error:', error);
        setError('Connection error. Please try again later.');
      };

      ws.onclose = (event) => {
        console.log('WebSocket Disconnected. Code:', event.code, 'Reason:', event.reason);
        setIsLoading(true);
        if (reconnectAttempts < maxReconnectAttempts) {
          const timeout = Math.pow(2, reconnectAttempts) * 1000;
          console.log(`Attempting to reconnect in ${timeout}ms...`);
          setTimeout(() => {
            reconnectAttempts++;
            connect();
          }, timeout);
        } else {
          setError('Unable to connect. Please check your network or try again later.');
          setIsLoading(false);
        }
      };
    };

    connect();

    return () => {
      if (ws) {
        console.log('Closing WebSocket connection');
        ws.close();
      }
    };
  }, []);

  const toggleExpand = () => setIsExpanded(!isExpanded);

  const sortedLookups = useMemo(() => {
    return [...lookups].sort((a, b) => b.count - a.count);
  }, [lookups]);

  const Row = useCallback(({ index, style }) => {
    const lookup = sortedLookups[index];
    return (
      <div style={style} className="text-xs py-1 px-2 flex items-center">
        <span className="font-semibold flex-grow truncate">{lookup.city?`${lookup.city}, ${lookup.country}` : lookup.country}</span>
        {lookup.count > 1 && (
          <span className="ml-1 bg-blue-100 text-blue-800 px-1 py-0.5 rounded text-[10px] leading-none">
            {lookup.count}
          </span>
        )}
      </div>
    );
  }, [sortedLookups]);

  return (
    <div className="bg-white bg-opacity-90 p-2 rounded-lg shadow absolute bottom-4 left-4 right-4 z-[1000]">
      <div className="flex justify-between items-center mb-1">
        <h3 className="text-xs font-semibold flex items-center">
          <Users className="mr-1" size={14} />
          {t('realtimeLookups')} ({sortedLookups.length})
        </h3>
        <button 
          onClick={toggleExpand}
          className="text-[10px] text-blue-500 hover:text-blue-700"
        >
          {isExpanded ? t('showLess') : t('showMore')}
        </button>
      </div>
      <div className={`${isExpanded ? 'h-32' : 'h-16'} transition-all duration-300`}>
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height}
              itemCount={sortedLookups.length}
              itemSize={20}
              width={width}
              overscanCount={5}
            >
              {Row}
            </List>
          )}
        </AutoSizer>
      </div>
    </div>
  );
};

export default RecentLookups;