export const LANGUAGES = [
  { code: 'en', name: 'English' },
  { code: 'ko', name: '한국어' },
  { code: 'zh', name: '中文' },
  { code: 'ja', name: '日本語' },
];

export const resources = {
  en: {
    translation: {
      description: "IP Geolocation Service",
      searchPlaceholder: "Enter IP address",
      loading: "Loading...",
      ipNotFound: "IP address not found",
      ipAddress: "IP Address",
      location: "Location",
      timezone: "Timezone",
      noData: "No data available",
      noMap: "Map not available",
      unknownCity: "Unknown City",
      unknownCountry: "Unknown Country",
      notAvailable: "N/A",
      locationUnknown: "Location Unknown",
      weatherClear: "Clear sky",
      weatherPartlyCloudy: "Partly cloudy",
      weatherCloudy: "Cloudy",
      weatherFog: "Fog",
      weatherDrizzle: "Drizzle",
      weatherFreezingDrizzle: "Freezing drizzle",
      weatherRain: "Rain",
      weatherFreezingRain: "Freezing rain",
      weatherSnow: "Snow",
      weatherSnowGrains: "Snow grains",
      weatherRainShowers: "Rain showers",
      weatherSnowShowers: "Snow showers",
      weatherThunderstorm: "Thunderstorm",
      weatherThunderstormWithHail: "Thunderstorm with hail",
      copiedToClipboard: "Copied to clipboard!",
      metaTitle: "ip2lo - IP Geolocation Service",
      metaDescription: "Accurate IP geolocation information. Find location details for any IP address.",
      ogTitle: "ip2lo: Instant IP Geolocation",
      ogDescription: "Discover precise locations for any IP address with ip2lo.",
      twitterTitle: "IP Geolocation Service | ip2lo",
      twitterDescription: "Get accurate location information for any IP address instantly with ip2lo.",
      realtimeLookups: "Recent Lookups",
      showLess: "Show Less",
      showMore: "Show More"
    }
  },
  ko: {
    translation: {
      description: "IP 위치 정보 서비스",
      searchPlaceholder: "IP 주소 입력",
      loading: "로딩 중...",
      ipNotFound: "IP 주소를 찾을 수 없습니다",
      ipAddress: "IP 주소",
      location: "위치",
      timezone: "시간대",
      noData: "데이터 없음",
      noMap: "지도를 표시할 수 없습니다",
      unknownCity: "알 수 없는 도시",
      unknownCountry: "알 수 없는 국가",
      notAvailable: "해당 없음",
      weatherClear: "맑음",
      weatherPartlyCloudy: "약간 흐림",
      weatherCloudy: "흐림",
      weatherFog: "안개",
      weatherDrizzle: "이슬비",
      weatherFreezingDrizzle: "얼어붙는 이슬비",
      weatherRain: "비",
      weatherFreezingRain: "얼어붙는 비",
      weatherSnow: "눈",
      weatherSnowGrains: "싸락눈",
      weatherRainShowers: "소나기",
      weatherSnowShowers: "눈보라",
      weatherThunderstorm: "뇌우",
      weatherThunderstormWithHail: "우박을 동반한 뇌우",
      locationUnknown: "위치 알 수 없음",
      copiedToClipboard: "클립보드에 복사되었습니다!",
      metaTitle: "ip2lo - IP 위치 정보 서비스",
      metaDescription: "정확한 IP 위치 정보를 제공합니다. 모든 IP 주소의 위치 상세 정보를 확인하세요.",
      ogTitle: "ip2lo: 즉각적인 IP 위치 정보",
      ogDescription: "ip2lo로 모든 IP 주소의 정확한 위치를 확인하세요.",
      twitterTitle: "IP 위치 정보 서비스 | ip2lo",
      twitterDescription: "ip2lo를 통해 모든 IP 주소의 정확한 위치 정보를 즉시 확인하세요.",
      realtimeLookups: "최근 조회",
      showLess: "간략히 보기",
      showMore: "더 보기"
    }
  },
  zh: {
    translation: {
      description: "IP地理位置服务",
      searchPlaceholder: "输入IP地址",
      loading: "加载中...",
      ipNotFound: "未找到IP地址",
      ipAddress: "IP地址",
      location: "位置",
      timezone: "时区",
      noData: "无可用数据",
      noMap: "地图不可用",
      unknownCity: "未知城市",
      unknownCountry: "未知国家",
      notAvailable: "不适用",
      locationUnknown: "位置未知",
      weatherClear: "晴天",
      weatherPartlyCloudy: "局部多云",
      weatherCloudy: "多云",
      weatherFog: "雾",
      weatherDrizzle: "毛毛雨",
      weatherFreezingDrizzle: "冻雨",
      weatherRain: "雨",
      weatherFreezingRain: "冻雨",
      weatherSnow: "雪",
      weatherSnowGrains: "雪粒",
      weatherRainShowers: "阵雨",
      weatherSnowShowers: "阵雪",
      weatherThunderstorm: "雷暴",
      weatherThunderstormWithHail: "雷暴伴有冰雹",
      copiedToClipboard: "已复制到剪贴板！",
      metaTitle: "ip2lo - IP地理位置服务",
      metaDescription: "提供精确的IP地理位置信息。查找任何IP地址的位置详情。",
      ogTitle: "ip2lo：即时IP地理位置",
      ogDescription: "使用ip2lo发现任何IP地址的精确位置。",
      twitterTitle: "IP地理位置服务 | ip2lo",
      twitterDescription: "使用ip2lo立即获取任何IP地址的准确位置信息。",
      realtimeLookups: "最近查询",
      showLess: "收起",
      showMore: "展开"
    }
  },
  ja: {
    translation: {
      description: "IP位置情報サービス",
      searchPlaceholder: "IPアドレスを入力",
      loading: "読み込み中...",
      ipNotFound: "IPアドレスが見つかりません",
      ipAddress: "IPアドレス",
      location: "場所",
      timezone: "タイムゾーン",
      noData: "データなし",
      noMap: "地図を表示できません",
      unknownCity: "不明な都市",
      unknownCountry: "不明な国",
      notAvailable: "該当なし",
      locationUnknown: "場所不明",
      weatherClear: "晴れ",
      weatherPartlyCloudy: "晴れ時々曇り",
      weatherCloudy: "曇り",
      weatherFog: "霧",
      weatherDrizzle: "霧雨",
      weatherFreezingDrizzle: "凍る霧雨",
      weatherRain: "雨",
      weatherFreezingRain: "凍る雨",
      weatherSnow: "雪",
      weatherSnowGrains: "細雪",
      weatherRainShowers: "にわか雨",
      weatherSnowShowers: "にわか雪",
      weatherThunderstorm: "雷雨",
      weatherThunderstormWithHail: "雹を伴う雷雨",
      copiedToClipboard: "クリップボードにコピーしました！",
      metaTitle: "ip2lo - IP位置情報サービス",
      metaDescription: "正確なIP位置情報を提供します。任意のIPアドレスの位置詳細を確認できます。",
      ogTitle: "ip2lo：即時IP位置情報",
      ogDescription: "ip2loで任意のIPアドレスの正確な位置を確認しましょう。",
      twitterTitle: "IP位置情報サービス | ip2lo",
      twitterDescription: "ip2loで任意のIPアドレスの正確な位置情報を即座に取得します。",
      realtimeLookups: "最近の検索",
      showLess: "表示を減らす",
      showMore: "もっと見る"
    }
  }
};