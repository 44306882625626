import React, { useState, useEffect } from 'react';
import { MapPin, Globe, Copy, Clock, Search, ChevronDown } from 'lucide-react';
import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { resources, LANGUAGES } from './i18n/resources';
import 'leaflet/dist/leaflet.css';
import 'weather-icons/css/weather-icons.css';
import L from 'leaflet';
import WeatherIcon from './components/WeatherIcon';
import MultilingualMetaTags from './components/MultilingualMetaTags';
import RecentLookups from './components/RecentLookups';

// Leaflet icon setup
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
});

// i18n setup
i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

const AutoOpenPopup = ({ ipData, weatherData }) => {
  const { t } = useTranslation();
  const map = useMap();

  useEffect(() => {
    if (ipData && ipData.latitude && ipData.longitude) {
      map.setView([ipData.latitude, ipData.longitude], 13);
    }
  }, [map, ipData]);

  if (!ipData || !ipData.latitude || !ipData.longitude) return null;

  return (
    <Marker position={[ipData.latitude, ipData.longitude]}>
      <Popup>
        <div className="text-center">
          <p className="font-bold">{ipData.city || t('unknownCity')}, {ipData.country || t('unknownCountry')}</p>
          {weatherData && (
            <div className="mt-2">
              <WeatherIcon weatherCode={weatherData.weathercode} />
              <p className="font-bold text-lg">{weatherData.temperature}°C</p>
            </div>
          )}
        </div>
      </Popup>
    </Marker>
  );
};

const MainContent = () => {
  const { lang } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [ipData, setIpData] = useState(null);
  const [weatherData, setWeatherData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchIp, setSearchIp] = useState('');
  const [error, setError] = useState(null);
  const [copySuccess, setCopySuccess] = useState(false);

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000);
    });
  };

  useEffect(() => {
    if (LANGUAGES.some(l => l.code === lang)) {
      i18n.changeLanguage(lang);
    } else {
      navigate('/en', { replace: true });
    }
  }, [lang, i18n, navigate]);

  const changeLanguage = (newLang) => {
    navigate(`/${newLang}`);
  };

  const fetchIpData = async (ip = '') => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`https://api.ip2lo.com/?ip=${ip ? `${ip}` : ''}`);
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      setIpData(data);
      fetchWeatherData(data.latitude, data.longitude);
    } catch (error) {
      console.error('Error fetching IP data:', error);
      setError(t('ipNotFound'));
      setIpData(null);
      setWeatherData(null);
    } finally {
      setLoading(false);
    }
  };

  const fetchWeatherData = async (lat, lon) => {
    if (!lat || !lon) {
      setWeatherData(null);
      return;
    }
    try {
      const response = await fetch(`https://api.open-meteo.com/v1/forecast?latitude=${lat}&longitude=${lon}&current_weather=true&language=${i18n.language}`);
      const data = await response.json();
      setWeatherData(data.current_weather);
    } catch (error) {
      console.error('Error fetching weather data:', error);
      setWeatherData(null);
    }
  };

  useEffect(() => {
    fetchIpData();
  }, []);

  useEffect(() => {
    if (ipData && ipData.latitude && ipData.longitude) {
      fetchWeatherData(ipData.latitude, ipData.longitude);
    }
  }, [i18n.language, ipData]);

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchIp) {
      fetchIpData(searchIp);
    }
  };

  return (
    <div className="bg-gradient-to-br from-gray-900 to-gray-800 flex justify-center items-center p-4">
      <MultilingualMetaTags language={i18n.language} />
      <div className="bg-white rounded-lg shadow-xl p-4 sm:p-6 w-full max-w-7xl h-[calc(100vh-2rem)] flex flex-col">
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <div>
            <h1 className="text-3xl font-bold text-blue-600">ip2lo</h1>
            <p className="text-sm text-gray-600">{t('description')}</p>
          </div>
          <>
            {/* Buttons for larger screens */}
            <div className="hidden sm:flex space-x-2">
              {LANGUAGES.map((lang) => (
                <button
                  key={lang.code}
                  onClick={() => changeLanguage(lang.code)}
                  className={`px-2 py-1 rounded transition-colors ${
                    i18n.language === lang.code
                      ? 'bg-blue-500 text-white'
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
                >
                  {lang.name}
                </button>
              ))}
            </div>

            {/* Select dropdown for mobile */}
            <div className="sm:hidden relative">
              <select
                value={i18n.language}
                onChange={(e) => changeLanguage(e.target.value)}
                className="appearance-none bg-white border border-gray-300 rounded-md py-2 pl-3 pr-10 text-sm leading-5 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              >
                {LANGUAGES.map((lang) => (
                  <option key={lang.code} value={lang.code}>
                    {lang.name}
                  </option>
                ))}
              </select>
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <ChevronDown size={16} />
              </div>
            </div>
          </>
        </div>
        
        {/* Main content */}
        <div className="flex flex-col sm:flex-row h-full">
          {/* Left side - IP and Weather info */}
          <div className="w-full sm:w-1/5 pr-4 flex flex-col">
            <form onSubmit={handleSearch} className="mb-4">
              <div className="flex">
                <input
                  type="text"
                  value={searchIp}
                  onChange={(e) => setSearchIp(e.target.value)}
                  placeholder={t('searchPlaceholder')}
                  className="flex-grow px-3 py-2 text-sm border border-gray-300 rounded-l-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-3 py-2 rounded-r-md hover:bg-blue-600 transition duration-300"
                >
                  <Search size={20} />
                </button>
              </div>
            </form>

            {loading ? (
              <div className="text-center py-12">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 mx-auto"></div>
                <p className="mt-2 text-sm text-gray-600">{t('loading')}</p>
              </div>
            ) : error ? (
              <div className="text-center py-12">
                <p className="text-red-500">{error}</p>
              </div>
            ) : ipData ? (
              <div className="space-y-4 flex-grow">
                <div className="bg-gray-100 p-3 rounded-lg">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center flex-grow">
                      <Globe className="text-blue-500 mr-3 flex-shrink-0" size={20} />
                      <div className="min-w-0 flex-grow">
                        <p className="font-semibold text-sm">{t('ipAddress')}</p>
                        <p className="text-gray-800 text-base font-mono truncate">{ipData.ip || t('notAvailable')}</p>
                      </div>
                    </div>
                    <button 
                      onClick={() => copyToClipboard(ipData.ip)}
                      className="ml-2 p-1.5 bg-blue-100 rounded-full hover:bg-blue-200 transition-colors flex-shrink-0"
                    >
                      <Copy size={16} className="text-blue-500" />
                    </button>
                  </div>
                  {copySuccess && (
                    <p className="text-green-500 text-xs mt-1">{t('copiedToClipboard')}</p>
                  )}
                </div>
                <div className="bg-gray-100 p-3 rounded-lg">
                  <div className="flex items-center">
                    <MapPin className="text-green-500 mr-3 flex-shrink-0" size={20} />
                    <div>
                      <p className="font-semibold text-sm">{t('location')}</p>
                      <p className="text-gray-600 text-sm">
                        {!ipData.city && !ipData.country ? t('locationUnknown') : ipData.city && ipData.country ? `${ipData.city}, ${ipData.country}` : ipData.country}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-100 p-3 rounded-lg">
                  <div className="flex items-center">
                    <Clock className="text-red-500 mr-3 flex-shrink-0" size={20} />
                    <div>
                      <p className="font-semibold text-sm">{t('timezone')}</p>
                      <p className="text-gray-600 text-sm">{ipData.timezone || t('notAvailable')}</p>
                    </div>
                  </div>
                </div>
                {weatherData && (
                  <div className="bg-gray-100 p-3 rounded-lg">
                    <WeatherIcon weatherCode={weatherData.weathercode} />
                    <div className="mt-2 text-center">
                      <p className="text-2xl font-bold">{weatherData.temperature}°C</p>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <p className="text-center text-gray-600 py-12">{t('noData')}</p>
            )}
          </div>
          
          {/* Right side - Map with Recent Lookups */}
          <div className="w-full sm:w-4/5 h-full sm:h-auto mt-4 sm:mt-0 relative">
            {ipData && ipData.latitude && ipData.longitude ? (
              <>
                <MapContainer 
                  center={[ipData.latitude, ipData.longitude]} 
                  zoom={13} 
                  style={{ height: '100%', width: '100%' }}
                  className="rounded-lg shadow-md"
                >
                  <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  />
                  <AutoOpenPopup ipData={ipData} weatherData={weatherData} />
                </MapContainer>
                <RecentLookups />
              </>
            ) : (
              <div className="h-full flex items-center justify-center bg-gray-100 rounded-lg">
                <p className="text-gray-500">{t('noMap')}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainContent;