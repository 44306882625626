import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import favicon from '../assets/favicon.png';
import ogImage from '../assets/og-image.png';
import { LANGUAGES } from '../i18n/resources';

const MultilingualMetaTags = () => {
  const { t, i18n } = useTranslation();

  return (
    <Helmet>
      <html lang={i18n.language} />
      <title>{t('metaTitle')}</title>
      <meta name="description" content={t('metaDescription')} />
      <link rel="icon" type="image/png" href={favicon} />
      <meta property="og:title" content={t('ogTitle')} />
      <meta property="og:description" content={t('ogDescription')} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`https://ip2lo.com/${i18n.language}`} />
      <meta property="og:image" content={`https://ip2lo.com${ogImage}`} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={t('twitterTitle')} />
      <meta name="twitter:description" content={t('twitterDescription')} />
      <meta name="twitter:image" content={`https://ip2lo.com${ogImage}`} />
      
      {LANGUAGES.map((lang) => (
        <link 
          key={lang.code} 
          rel="alternate" 
          hrefLang={lang.code} 
          href={`https://ip2lo.com/${lang.code}`} 
        />
      ))}
      <link rel="alternate" hrefLang="x-default" href="https://ip2lo.com" />
    </Helmet>
  );
};

export default MultilingualMetaTags;